




































































import {
  Component,
  Inject,
  InjectReactive,
  Vue,
} from 'vue-property-decorator';
import FindUser from '@/views/users/components/FindUser.vue';
import NewUserForm from '@/views/users/components/NewUserForm.vue';
import VbDialog from '@/components/VbDialog.vue';
import { User } from '@/models/entities/User';
import { Course } from '@/models/entities/Course';
import {
  RolesForCourse,
  UserRoleIds,
  UserRoleIdsIri,
  UserRoleIriNames,
} from '@/types/UserRoleIds';
import { ApiFacade } from '@/services/ApiFacade';
import { HydraError } from '@/api/types/HydraTypes';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { AppStore } from '@/store/types/AppStore';

// FindUser
interface CourseStudentsAddEditUserFormView {
  $refs: {
    findUserCmp: FindUser;
  };
}

@Component({
  components: {
    ErrorAlert,
    VbDialog,
    FindUser,
    NewUserForm,
  },
})
class CourseStudentsAddEditUserFormView extends Vue {
  @InjectReactive() course!: Course;
  @Inject() apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  @Inject() isCourseTeacher!: () => boolean;
  @Inject() isCourseAdmin!: () => boolean;

  foundUser: User | null = null;

  isEditMode: boolean = false;
  isValid: boolean = true;
  isInProcess: boolean = false;

  isLoading: boolean = false;

  role: RolesForCourse = UserRoleIdsIri.student;
  error: HydraError | null = null;

  mounted() {
    if (this.$route.query.email) {
      this.$refs.findUserCmp.email = String(this.$route.query.email);
      this.$nextTick()
        .then(() => {
          this.$refs.findUserCmp.findUserByEmail();
        });
    }
  }

  get allowedRoles(): Partial<Record<RolesForCourse, string>> {
    if (this.appStore.getters.profile?.isSuperAdmin || this.isCourseAdmin()) {
      return {
        [UserRoleIdsIri.admin]: UserRoleIriNames[UserRoleIdsIri.admin],
        [UserRoleIdsIri.teacher]: UserRoleIriNames[UserRoleIdsIri.teacher],
        [UserRoleIdsIri.student]: UserRoleIriNames[UserRoleIdsIri.student],
      };
    }

    return {
      [UserRoleIdsIri.student]: UserRoleIriNames[UserRoleIdsIri.student],
    };
  }

  get title() {
    return 'Добавление пользователя';
  }

  isUserAddedToCourse(user: User): boolean {
    return this.course.courseUsers.findIndex((rel) => rel.user.userId === user.userId) !== -1;
  }

  handleClose() {
    this.$router.push({
      name: 'course-students',
      params: { courseId: String(this.course.courseId) },
    });
  }

  async handleSave() {
    if (!this.foundUser) {
      return;
    }
    let user;
    try {
      this.isInProcess = true;
      if (!this.foundUser?.userId) {
        this.foundUser.userRolePolygons.push({
          userRole: { userRoleId: UserRoleIds.student },
          polygon: this.course.polygon,
        });
        user = await this.apiFacade.createNewUser(this.foundUser);
      } else {
        user = this.foundUser; // cloneDeep(this.foundUser);
      }
      // const rel:Course['courseUsers'][0] = {}
      // const index = this.course

      // this.course.
      // console.log(user);

      await this.apiFacade.updateCourseUserRelation(this.course, user, this.role);
    } catch (err) {
      this.error = err;
    } finally {
      this.isInProcess = false;
    }

    this.$emit('update-user', user);
    this.handleClose();

    // return 1;
  }

  handleFindUserResult(user: User | null) {
    this.isValid = true;
    let rel;
    if (user?.userId) {
      rel = this.course.courseUsers.find((r) => r.user.userId === user.userId);
    }
    this.role = rel
      ? UserRoleIdsIri[rel.userRole.userRoleId]
      : UserRoleIdsIri.student;
    this.foundUser = user;

    if (user && this.course.hasUser(user)) {
      this.isValid = false;
    }
  }

  onUserFormValidation(validation: boolean) {
    this.isValid = Boolean(validation);
  }
}

export default CourseStudentsAddEditUserFormView;
